import type { CharacteristicFilter } from "~types/api/characteristic.types";
import type { Product } from "~types/api/product.types";
import type { Range } from "~types/api/range.types";

import {
  staticCharacteristicsData,
  staticCharacteristicsDataForFilters,
} from "~/config/characteristics";

export const getCharacteristicValue = (item: Product | Range, slug: string) => {
  let characteristics: Product["characteristicProductValues"] | Range["characteristicRangeValues"] =
    [];

  if ("characteristicProductValues" in item) {
    characteristics = item.characteristicProductValues;
  }

  if ("characteristicRangeValues" in item) {
    characteristics = item.characteristicRangeValues;
  }

  return characteristics.find((characteristic) => characteristic.slug === slug)?.value;
};

export const getTechnicalElements = (product: Product) => {
  if (!product.characteristicProductValues) {
    return null;
  }
  return product.characteristicProductValues
    .filter(
      (characteristic) =>
        characteristic.slug?.startsWith("element-technique-") && characteristic.value !== null
    )
    .map((characteristic) => {
      const link = product.characteristicProductValues.find(
        (c) => c.slug === `lien-${characteristic.slug}`
      )?.value;
      return {
        ...characteristic,
        link,
      };
    })
    .sort((a, b) => {
      const order =
        parseInt(a.slug?.split("element-technique-")[1] || "0") -
        parseInt(b.slug?.split("element-technique-")[1] || "0");
      return order;
    });
};

export const getHighlights = (product: Product) => {
  if (!product.characteristicProductValues) {
    return null;
  }
  return product.characteristicProductValues
    .filter(
      (characteristic) =>
        characteristic.slug?.startsWith("point-fort-") && characteristic.value !== null
    )
    .sort((a, b) => {
      const order =
        parseInt(a.slug?.split("point-fort-")[1] || "0") -
        parseInt(b.slug?.split("point-fort-")[1] || "0");
      return order;
    });
};

export const getFormatedCharacteristicsForFilters = ({
  categorySlug, // current category slug
  characteristicFilters, // characteristics from API
}: {
  categorySlug?: string | null | undefined;
  characteristicFilters: CharacteristicFilter[];
}) => {
  const staticCharacteristicFiltersForCurrentCategory = categorySlug
    ? staticCharacteristicsDataForFilters[
        categorySlug as keyof typeof staticCharacteristicsDataForFilters
      ] || []
    : Object.values(staticCharacteristicsData) || [];

  return staticCharacteristicFiltersForCurrentCategory.map((staticCharacteristicsCategory) => {
    const formatedCharacteristics = staticCharacteristicsCategory.characteristics
      .map((staticCharacteristic) => {
        const characteristicFilter = characteristicFilters.find(
          (characteristicFilter) =>
            characteristicFilter.characteristic.slug === staticCharacteristic.slug
        );

        if (!characteristicFilter) {
          return null;
        }

        if (
          // characteristicFilter?.numeric?.min === characteristicFilter?.numeric?.max ||
          characteristicFilter.numeric?.min === 0 &&
          characteristicFilter.numeric.max === 0
        ) {
          return null;
        }

        return {
          ...staticCharacteristic,
          ...characteristicFilter.characteristic,
          min: Math.floor(characteristicFilter.numeric?.min || 0),
          max: Math.ceil(characteristicFilter.numeric?.max || 0),
          unit:
            staticCharacteristic && "unit" in staticCharacteristic
              ? staticCharacteristic.unit?.[
                  categorySlug as keyof typeof staticCharacteristic.unit
                ] ||
                (staticCharacteristic.unit as any)?.default ||
                null
              : null,
          matchingCount: characteristicFilter.matchingCount,
        };
      })
      .filter((characteristic) => characteristic !== undefined && characteristic !== null);
    return {
      title: staticCharacteristicsCategory.title,
      characteristics: formatedCharacteristics,
      param: staticCharacteristicsCategory.param,
    };
  });
};
